@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  max-width: 1920px;
  margin: 0 auto;
}

.banner-swiper .swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 10px;
  padding-inline-end: 10px;
}

.banner-swiper .swiper-pagination-bullet {
  opacity: 1 !important;
  background-color: #fff !important;
  width: 4px !important;
  height: 4px !important;
}

.banner-swiper .swiper-pagination-bullet-active {
  width: 24px !important;
  border-radius: 4px;
}

/* React Select Styles */
.custom-react-select__placeholder {
  font-size: 14px;
  color: #aaaaa0 !important;
}

.custom-react-select__indicator {
  color: #dfdfdf !important;
}

@media (max-width: 640px) {
  .custom-react-select__menu {
    font-size: 12px;
  }

  .custom-react-select__single-value {
    font-size: 14px;
  }
}

@media (min-width: 640px) {
  .custom-react-select__control {
    padding: 0.14rem;
  }

  .custom-react-select__placeholder {
    display: none;
  }
}

.loader {
  width: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, currentColor 94%, #0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%, currentColor);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
  animation: l13 1s infinite linear;
}

@keyframes l13 {
  100% {
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn);
    -moz-transform: rotate(1turn);
    -ms-transform: rotate(1turn);
    -o-transform: rotate(1turn);
  }
}

.loader-xl {
  width: 80px;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, currentColor 94%, #0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%, currentColor);
  mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: l13 1s infinite linear;
}

.container {
  @apply w-11/12 mx-auto max-w-[1440px]
}

.gray-scroll::-webkit-scrollbar {
  width: 0.2em;
}

.gray-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.gray-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}

.indent-second-line li {
  text-indent: -20px; /* Indent the first line to the left */
  padding-left: 20px; /* Indent the entire list item to the right */
}

.indent-second-line li ul {
  text-indent: -30px; /* Indent the first line of nested list items to the left */
  padding-left: 30px; /* Indent the entire nested list item to the right */
}